<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img class="" :src="logo" max-height="150" contain />
        </v-col>
      </v-row>
    </v-container>
    <v-container fill-height>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <h2 class="">Sistema de Registro de Ingreso y Salida de Personal de Notaría Pública No.51</h2>
          </div>
        </v-col>
        <v-col cols="6" class="d-flex justify-end mb-6">
          <v-card max-width="500px" color="primary" class="select" @click="$router.push('/nueva-solicitud-empleados')" dark>
            <v-card-text class="text-center mt-5">
              <v-icon size="70">mdi-file-document-plus</v-icon>
              <!--<lottie-animation ref="anim1" :animationData="require('@/assets/animations/file.json')" style="width: 100%; height: 100px" />-->
            </v-card-text>
            <v-card-title class="text-h5 itemsCenter"> Nueva solicitud </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="6" class="d-flex justify-start mb-6">
          <v-card max-width="500px" color="primary" class="select" @click="$router.push('/solicitud-empleados')" dark>
            <v-card-text class="text-center mt-5">
              <v-icon size="70">mdi-clipboard-text-clock</v-icon>
              <!--<lottie-animation ref="anim2" :animationData="require('@/assets/animations/clipboard.json')" class="pa-5" style="width: 100%; height: 100px" />-->
            </v-card-text>
            <v-card-title class="text-h5 itemsCenter">Ver mis solicitudes </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
        {{ msgSuccess }}
      </v-snackbar>
      <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
        {{ msgError }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import "animate.css";
// import LottieAnimation from "lottie-web-vue";

export default {
  name: "home",
  /*components: {
    LottieAnimation,
  },*/
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    modulos: [],
    logo: require("@/assets/images/logo51.png"),
  }),
  methods: {},
};
</script>

<style scoped>
  .select {cursor: pointer;}
</style>
